import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import type { ZodModifier, ZodModifierGroup } from '@orderhub/shared';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  modifierGroup: ZodModifierGroup;
  modifiers: ZodModifier[];
  // React hook form props
  onChange: (...event: any[]) => void;
  selectedModifier: ZodModifier | null;
};
export default function ModifierSelectList({
  modifierGroup,
  modifiers,
  onChange,
  selectedModifier,
}: Props) {
  if (modifiers.length === 0) return null;

  // FIXME: Add a "None" option for SIZE mod groups to handle selectedModifier === null.
  if (!selectedModifier) return null;

  return (
    // Note: it's important that the "by" attribute is set here, as this allows the object to be compared by the "id" property.
    // Without this, the `selected` value will not be set correctly (and tick not shown), because the object is not the exact same object.
    <Listbox value={selectedModifier} onChange={onChange} by={'id'}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-semibold text-gray-700">
            {modifierGroup.name}
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-gray-900 focus:outline-none focus:ring-1 focus:ring-gray-500 sm:text-sm">
              <span className="inline-flex w-full truncate">
                <span className="truncate">{selectedModifier.name}</span>
                {selectedModifier.price !== 0 && (
                  <span className="ml-2 text-gray-400">
                    {/* Don't show the + symbol for SIZE mod groups */}
                    {modifierGroup.modifierType !== 'SIZE'
                      ? `+$${selectedModifier.price.toFixed(2)}`
                      : `$${selectedModifier.price.toFixed(2)}`}
                  </span>
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {modifiers.map((modifier) => (
                  <Listbox.Option
                    key={modifier.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-gray-100' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-8 pr-4'
                      )
                    }
                    value={modifier}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal')}>
                            {modifier.name}
                          </span>

                          {modifier.price !== 0 && (
                            <span
                              className={classNames(
                                active ? 'text-gray-400' : 'text-gray-400',
                                'ml-2'
                              )}
                            >
                              {modifierGroup.modifierType !== 'SIZE'
                                ? `+$${modifier.price.toFixed(2)}`
                                : `$${modifier.price.toFixed(2)}`}
                            </span>
                          )}
                        </div>

                        {selected ? (
                          // Select Checkmark
                          <span
                            className={classNames(
                              active ? 'text-black' : 'text-black',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
