import type { ZodModifierGroup, ZodModifier } from '@orderhub/shared';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

type Props = {
  modifierGroups: ZodModifierGroup[];
  modifiers: ZodModifier[];
  // React hook form props
  onChange: (...event: any[]) => void;
  selectedModifiers: ZodModifier[];
};

const ModifierMultiSelectList = ({
  modifierGroups,
  modifiers,
  selectedModifiers,
  onChange,
}: Props) => {
  // Because <input ..> `value` prop needs to be a string, we store a list of ids, then map these back to
  // the Modifier objects (to keep consistent with 'one' modifiers).
  const [selectedModifierIds, setSelectedModifierIds] = useState<string[]>(
    selectedModifiers.map((m) => m.id)
  );

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedModifierIds) {
      if (e.target.checked) {
        setSelectedModifierIds([
          ...selectedModifierIds.filter((id) => id !== e.target.value),
          e.target.value,
        ]);
      } else {
        setSelectedModifierIds([...selectedModifierIds.filter((id) => id !== e.target.value)]);
      }
    }
  };

  useEffect(() => {
    onChange(modifiers.filter((m) => selectedModifierIds.includes(m.id)));
  }, [selectedModifierIds]);

  if (modifierGroups.length === 0 || modifiers.length === 0) return null;
  return (
    <fieldset>
      <legend className="text-lg font-medium text-gray-900">Extras</legend>
      <div className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200 bg-white">
        {modifiers.map((modifier) => (
          <div key={modifier.id} className="relative flex items-start py-3 pl-2">
            {/* Checkbox */}
            <div className="flex h-5 items-center">
              <input
                id={`modifier-${modifier.id}`}
                name={`modifier-${modifier.id}`}
                type="checkbox"
                value={modifier.id}
                className="h-4 w-4 cursor-pointer rounded border-gray-300 text-gray-900 focus:ring-gray-600"
                onChange={handleOnChange}
                checked={selectedModifiers.map((m) => m.id).includes(modifier.id)}
              />
            </div>

            {/* Label */}
            <div className="ml-2 min-w-0 flex-1 text-sm">
              <label
                htmlFor={`modifier-${modifier.id}`}
                className="cursor-pointer select-none text-gray-700"
              >
                <span>{modifier.name}</span>
                {modifier?.price !== 0 && (
                  <span className="ml-2 text-gray-400">{`+$${modifier.price.toFixed(2)}`}</span>
                )}
              </label>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default ModifierMultiSelectList;
